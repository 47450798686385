import React from "react";
import { Query, Mutation } from "react-apollo";
import { useTranslation } from "react-i18next";

import Select from "react-select";
import { GET_DAYCARE_CENTERS_LIST, SET_SELECTED_DAYCARE_CENTER, GET_GLOBAL_FILTERS } from "../../queries";

import { Loader, pushQueryToHistory, ErrorMessage } from "../../shared";

export const sidebarSelectStyles = {
  control: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      height: "56px",
      minHeight: "fit-content",
      borderRadius: "56px",
      boxShadow: "none",
      border: "none",
      backgroundColor: "#ebeef5",
    };
  },
  indicatorSeparator: styles => ({
    ...styles,
    background: "transparent",
  }),
  singleValue: styles => ({
    ...styles,
    color: "#143c8c",
    fontWeight: 500,
  }),
  dropdownIndicator: styles => ({
    ...styles,
    paddingRight: "24px",
    color: "#143c8c",
  }),
  indicatorsContainer: provided => ({
    ...provided,
  }),
  valueContainer: styles => ({
    ...styles,
    paddingLeft: "24px",
    maxHeight: "56px",
    outline: "none !important",
  }),
  menuList: base => ({
    ...base,
    minHeight: "fit-content",
  }),
};

const DaycareSelector = ({setDaycareId, setGroupId}) => {
  const { t } = useTranslation();
  return (
    <Query query={GET_DAYCARE_CENTERS_LIST}>
      {({ data, loading, error }) => {
        if (error)
          return (
            <ErrorMessage className="margin-16 margin-top">{t("Staff.DaycareSelector.loading_error")}</ErrorMessage>
          );
        if (loading)
          return <Loader text={t("Staff.DaycareSelector.loading")} inline className="margin-16 margin-top" />;

        const { daycareCenters } = data;

        return (
          <Mutation mutation={SET_SELECTED_DAYCARE_CENTER} refetchQueries={[{ query: GET_GLOBAL_FILTERS }]}>
            {setSelectedDaycareCenter => {
              const options = (daycareCenters || []).map(daycareCenter => ({
                value: daycareCenter.id,
                label: daycareCenter.name,
              }));
              options.splice(0, 0, { value: "", label: t("Staff.DaycareSelector.all_daycare_centers") });
              return (
                <Select
                  name="selectedDaycareCenter"
                  value={options.find(option => option.value === data.selectedDaycareCenter) || ""}
                  options={options}
                  styles={sidebarSelectStyles}
                  onChange={option =>
                    setSelectedDaycareCenter({
                      variables:
                        option.value !== data.selectedDaycareCenter
                          ? { id: option.value, group_id: "" }
                          : { id: option.value },
                    }).then(() => {
                      let searchQuery = new URLSearchParams(window.location.search);

                      if (!option.value || (option.value && option.value.length === 0)) {
                        searchQuery.delete("daycareId");
                        searchQuery.delete("groupId");
                        pushQueryToHistory(searchQuery);
                        setDaycareId("")
                        setGroupId("")
                      } else if (option.value !== data.selectedDaycareCenter) {
                        // Mutate the url to include the change
                        searchQuery.set("daycareId",option.value);
                        searchQuery.delete("groupId");
                        pushQueryToHistory(searchQuery);
                        setDaycareId('?daycareId=' + option.value)
                        setGroupId("")
                      }
                    })
                  }
                />
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default DaycareSelector;
