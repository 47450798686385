import React from "react";
import { Page, Text, View, Image, Document, Font, StyleSheet } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import tougoLogo from "../../assets/images/touhula.png";
import maaxRegular from "../../assets/fonts/Maax-Regular-205TF.ttf";
import maaxItalic from "../../assets/fonts/Maax-Italic-205TF.ttf";
import maaxMedium from "../../assets/fonts/Maax-Medium-205TF.ttf";
import maaxBold from "../../assets/fonts/Maax-Bold-205TF.ttf";

const LINECOUNT = 40;
// This component is in both staff and customer.
// If you make changes in one, copy them to the other.

// This is done because attempting to import react-pdf from shared caused a compile error.
// TODO: fix that (see https://github.com/diegomura/react-pdf/issues/643 for potential fix).

Font.register({
  family: "Maax",
  fonts: [
    {
      src: window.location.origin + maaxRegular,
      fontWeight: "400",
      fontStyle: "normal",
    },
    {
      src: window.location.origin + maaxItalic,
      fontWeight: "400",
      fontStyle: "italic",
    },
    {
      src: window.location.origin + maaxMedium,
      fontWeight: "500",
      fontStyle: "normal",
    },
    {
      src: window.location.origin + maaxBold,
      fontWeight: "700",
      fontStyle: "normal",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Maax",
    fontSize: 10,
    paddingTop: 25,
    paddingBottom: 25,
    paddingHorizontal: 25,
  },
  paragraph: {
    marginVertical: 4,
  },
  emphasis: {
    fontStyle: "italic",
  },
  strong: {
    fontWeight: 700,
  },
  strikethrough: {
    textDecoration: "line-through",
  },
  list: {
    marginVertical: 4,
    display: "flex",
    flexDirection: "column",
  },
  nestedList: {
    marginLeft: 15,
    display: "flex",
    flexDirection: "column",
  },
  listItem: {
    flexDirection: "row",
    marginLeft: 5,
  },
  listItemBullet: {
    width: 3,
    height: 3,
    backgroundColor: "black",
    borderRadius: 3,
    marginTop: 5,
    marginRight: 12,
  },
  listItemValue: {
    paddingRight: 10,
  },
  orderedListItemIndex: {
    width: 15,
  },
  bigHeading: {
    fontSize: 16,
    fontWeight: 700,
    marginVertical: 10,
  },
  smallHeading: {
    fontSize: 14,
    fontWeight: 700,
    marginVertical: 10,
  },
  blockquote: {
    paddingLeft: 10,
    borderLeft: "2 solid #888",
  },
  codeBlock: {
    padding: 10,
    backgroundColor: "#eee",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 25,
  },
  headerRight: {
    flexDirection: "column",
    alignItems: "flex-end",
  },
  section: {
    marginBottom: 25,
  },
  lowMarginSection: {
    marginBottom: 5,
  },
  childInfo: {
    borderTop: "1 solid black",
    borderBottom: "1 solid black",
    paddingVertical: 10,
    marginBottom: 10,
  },
  childInfoRow: {
    flexDirection: "row",
    fontSize: 13,
    marginVertical: 2,
  },
  childInfoLabel: {
    width: 120,
  },
  childInfoValue: {
    fontWeight: 500,
  },
  documentInfo: {
    marginBottom: 10,
  },
  documentInfoRow: {
    flexDirection: "row",
    fontSize: 11,
    marginVertical: 2,
  },
  documentInfoLabel: {
    width: 120,
  },
  documentInfoValue: {
    fontWeight: 500,
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.4,
    marginBottom: 10,
  },
  questionTitle: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.4,
    marginBottom: 10,
  },
  questionSubtitle: {
    marginBottom: 5,
  },
  field: {
    border: "1 solid black",
    borderRadius: 4,
    padding: 5,
    minHeight: 50,
    marginVertical: 5,
  },
  fieldTop: {
    border: "1 solid black",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    padding: 5,
    minHeight: 50,
  },
  fieldMiddle: {
    border: "1 solid black",
    borderTop: "none",
    padding: 5,
    minHeight: 50,
  },
  fieldBottom: {
    border: "1 solid black",
    borderTop: "none",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    padding: 5,
    minHeight: 50,
  },
  doubleField: {
    flexDirection: "row",
    alignItems: "stretch",
  },
  doubleFieldItem: {
    alignSelf: "stretch",
    flexBasis: "50%",
    flexDirection: "column",
  },
  doubleFieldFirst: {
    flexGrow: 1,
    border: "1 solid black",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    padding: 5,
    minHeight: 70,
  },
  doubleFieldSecond: {
    flexGrow: 1,
    border: "1 solid black",
    borderLeft: "none",
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: 5,
    minHeight: 70,
  },
  row: {
    flexDirection: "row",
    paddingBottom: 5,
    fontSize: 9,
  },
  rowTitle: {
    flexBasis: "19%",
    paddingRight: 8,
    paddingTop: 5,
  },
  rowCell: {
    minHeight: 70,
    flexBasis: "27%",
    borderRight: "1 solid black",
    borderBottom: "1 solid black",
    borderTop: "1 solid black",
    paddingHorizontal: 4,
  },
  rowCellFirst: {
    minHeight: 70,
    flexBasis: "27%",
    border: "1 solid black",
    paddingHorizontal: 4,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  rowCellLast: {
    minHeight: 70,
    flexBasis: "27%",
    borderRight: "1 solid black",
    borderBottom: "1 solid black",
    borderTop: "1 solid black",
    paddingHorizontal: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  colEmpty: {
    flexBasis: "19%",
  },
  colTitle: {
    flexBasis: "27%",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 8,
    paddingBottom: 3,
  },
  periods: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid black",
    marginBottom: 25,
    borderRadius: 4,
  },
  period: {
    padding: 10,
    flexBasis: "33.3%",
    borderRight: "1px solid black",
  },
  lastPeriod: {
    padding: 10,
    flexBasis: "33.3%",
  },
  periodHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 10,
  },
  periodDateTitle: {
    fontWeight: 500,
  },
  checkbox: {
    width: 10,
    height: 10,
    border: "1px solid black",
    borderRadius: 10,
    marginRight: 5,
  },
  activeCheckbox: {
    width: 10,
    height: 10,
    border: "1px solid black",
    backgroundColor: "black",
    borderRadius: 10,
    marginRight: 5,
  },
  periodTitle: {
    fontSize: 12,
  },
  periodTitleActive: {
    fontSize: 12,
    fontWeight: 500,
  },
});

// Fix markdown lists that come incompatible from editor
const fixLists = (text) => {
// 1. Remove empty non-printing items, ie. "\u200B"
  text = text.replace(/\u200B/g, "");

// 2. Remove empty list items, ie. lines with a dash/number+dot and whitespace
  text = text.replace(/^-\s*\n/gm, "\n");
  text = text.replace(/(^\d{1,2})\.\s*\n/gm, "$1\n");

// 3. If use has added lines consequtively, assume that they want a list
  text = text.replace(/(^- .*\n)\n(?=- )/gm, "$1");
  text = text.replace(/(^\d{1,2}\. .*\n)\n(?=\d{1,2}\. )/gm, "$1");
  return text;
};

const renderers = {
  p: ({ children }) => {
    if (children == undefined) {
      return null;
    } else {
      return <Text style={styles.paragraph}>{children}</Text>
    }
  },
  em: ({ children }) => {
    if (children[0] && children[0].props) {
      return <View style={styles.emphasis}>{children}</View>;
    } else {
      return <Text style={styles.emphasis}>{children}</Text>;
    }
  },
  strong: ({ children }) => {
    if (children[0] && children[0].props) {
      return <View style={styles.strong}>{children}</View>;
    } else {
      return <Text style={styles.strong}>{children}</Text>;
    }
  },
  del: ({ children }) => {
    if (children[0] && children[0].props) {
      return <View style={styles.strikethrough}>{children}</View>
    } else {
      return <Text style={styles.strikethrough}>{children}</Text>
    }
  },
  ol: ({ children }) => <View style={styles.list} wrap={false}>{children}</View>,
  ul: ({ children }) => <View style={styles.list} wrap={false}>{children}</View>,
  li: (props) => {
    if (props.ordered) {
      if (!props.children) {
        return null;
      }

      return (
        <>
          <View style={styles.listItem}>
            <Text style={styles.orderedListItemIndex}>{props.index + 1}.</Text>
            <Text style={styles.listItemValue}>{props.children[0]}</Text>
          </View>
          {props.children && props.children.map((child) => {
            if (
              child.props &&
              child.props &&
              child.props.node &&
              child.props.node.tagName &&
              (child.props.node.tagName === "ol" || child.props.node.tagName === "ul")
            ) {
              return (
                <View key={child.key} style={styles.nestedList}>
                  {child}
                </View>
              );
            } else return null;
          })}
        </>
      );
    } else {
      if (!props.children) {
        return null;
      }

      return (
        <>
          <View style={styles.listItem}>
            <View style={styles.listItemBullet}></View>
            <Text style={styles.listItemValue}>{props.children[0]}</Text>
          </View>
          {props.children && props.children.map((child) => {
            if (
              child.props &&
              child.props.node &&
              child.props.node.tagName &&
              (child.props.node.tagName === "ol" || child.props.node.tagName === "ul")
            ) {
              return (
                <View key={child.key} style={styles.nestedList}>
                  {child}
                </View>
              );
            } else
               {
                 return null;
            }
          })}
        </>
      );
    }
  },
  blockquote: ({ children }) => <View style={styles.blockquote}>{children}</View>,
  h1: (props) => <Text style={styles.bigHeading}>{props.children}</Text>,
  h2: (props) => <Text style={styles.bigHeading}>{props.children}</Text>,
  h3: (props) => <Text style={styles.smallHeading}>{props.children}</Text>,
  h4: (props) => <Text style={styles.smallHeading}>{props.children}</Text>,
  h5: (props) => <Text style={styles.smallHeading}>{props.children}</Text>,
  h6: (props) => <Text style={styles.smallHeading}>{props.children}</Text>,
  a: ({ children }) => <Text>{children}</Text>,
  code: ({ value }) => <Text style={styles.codeBlock}>{value}</Text>,
  pre: ({ value }) => <Text style={styles.codeBlock}>{value}</Text>,
};

const Markdown = ({ source }) => {
  return (
    <ReactMarkdown
      children={source}
      components={renderers}
      remarkPlugins={[remarkGfm]}
      disallowedElements={["hr", "br", "img", "html"]}
      unwrapDisallowed={false}
      skipHtml={true}
    />
  );
};

const VasuPDF = ({ child, document }) => {
  const { t } = useTranslation();

  console.log(document);

  const getFieldValue = (key) => {
    const fieldValue =
      document.formFieldsData &&
      document.formFieldsData.length &&
      document.formFieldsData.find((field) => field.key === key);

    if (fieldValue) {
      return fieldValue.value;
    } else {
      return "";
    }
  };

  const getMatrixValue = (rowPos, columnPos) => {
    const objectiveValue =
      document.objectiveValues &&
      document.objectiveValues.length &&
      document.objectiveValues.find(
        (objectiveValue) =>
          objectiveValue.documentRow.position === rowPos && objectiveValue.documentColumn.position === columnPos
      );

    if (objectiveValue) {
      return objectiveValue.value;
    } else {
      return "";
    }
  };

  const lowercaseFirstLetter = (word) => {
    return word.charAt(0).toLowerCase() + word.slice(1);
  };

  const MatrixRow = ({ title, row }) => {
    const first = getMatrixValue(row, 1);
    const second = getMatrixValue(row, 2);
    const third = getMatrixValue(row, 3);
    const chunkLength = 800;

    const chunkCol = (field, col) => {
      let charCount = 0;
      let chunks = [];

      field = fixLists(field);

      if (field.length > chunkLength) {
        while (charCount < field.length) {
          const wordBreakChunk = field.slice(charCount + chunkLength, charCount + chunkLength + 100);
          const wordBreak = wordBreakChunk.split(" ", 1);
          const wordBreakLength = wordBreak[0].length;

          const chunk = field.slice(charCount, charCount + chunkLength + wordBreakLength);

          chunks.push(chunk);
          charCount += chunkLength + wordBreakLength + 1;
        }
      } else {
        chunks.push(field);
      }

      return {
        col: col,
        length: chunks.length,
        chunks: chunks,
      };
    };

    const getChunkRows = (cols) => {
      let longestLength = 0;
      let rows = [];

      cols.forEach((col) => {
        if (col.chunks.length > longestLength) {
          longestLength = col.chunks.length;
        }
      });

      for (let index = 0; index < longestLength; index++) {
        let row = {
          index: index,
          1: "",
          2: "",
          3: "",
        };

        cols.forEach((col) => {
          if (col.chunks[index]) {
            row[col.col] = col.chunks[index];
          }
        });

        rows.push(row);
      }

      return rows;
    };

    const chunkColumns = [chunkCol(first, 1), chunkCol(second, 2), chunkCol(third, 3)];
    const chunkRows = getChunkRows(chunkColumns);

    return (
      <>
        <View wrap={false} style={row === 6 && styles.section}>
          {row === 1 ? (
            <View>
              <Text style={styles.questionTitle}>{"7. " + t("Vasu.goals_of_activities")}</Text>
              <Text style={styles.questionSubtitle}>{t("Vasu.goals_of_activities_description")}</Text>
              <View style={styles.row}>
                <View style={styles.colEmpty}></View>
                <View style={styles.colTitle}>
                  <Text>{t("Vasu.activity_to_be_practised")}</Text>
                </View>
                <View style={styles.colTitle}>
                  <Text>{t("Vasu.solutions")}</Text>
                </View>
                <View style={styles.colTitle}>
                  <Text>{t("Vasu.assessment")}</Text>
                </View>
              </View>
            </View>
          ) : null}
          <View style={styles.row}>
            <View style={styles.rowTitle}>
              <Text>{title}</Text>
            </View>
            <View style={styles.rowCellFirst}>
              <Markdown source={chunkRows[0][1]} />
            </View>
            <View style={styles.rowCell}>
              <Markdown source={chunkRows[0][2]} />
            </View>
            <View style={styles.rowCellLast}>
              <Markdown source={chunkRows[0][3]} />
            </View>
          </View>
        </View>
        {chunkRows.length > 1 &&
          chunkRows.map((row, index) => {
            if (index === 0) return null;
            return (
              <View key={row.index} style={styles.row} wrap={false}>
                <View style={styles.rowTitle}></View>
                <View style={styles.rowCellFirst}>
                  <Markdown source={row[1]} />
                </View>
                <View style={styles.rowCell}>
                  <Markdown source={row[2]} />
                </View>
                <View style={styles.rowCellLast}>
                  <Markdown source={row[3]} />
                </View>
              </View>
            );
          })}
      </>
    );
  };

  const getChunks = (text, lineLength, lineCount) => {
    let chunks = [];
    let currentChunk = "";
    let lines = text.match(/([^\n]+\n{1,2})/g) || [];
    let lineCounter = 1;
    let remainingLines = lineCount;
    let consumedLines = 0;

    lines.forEach((line, index) => {
      consumedLines = Math.ceil(line.length / lineLength);
      if (remainingLines - consumedLines >= 0) {
        currentChunk += line;
        remainingLines -= consumedLines;
        if (line.indexOf("\n\n") == -1) remainingLines -= 1;
      } else {
        chunks.push(currentChunk);
        currentChunk = line;
        remainingLines = lineCount - consumedLines;
      }
    });

    chunks.push(currentChunk);
    return chunks;
  };

  const StandardField = ({ title, subtitle, subquestion, field, lineCount, lowMargin }) => {
    let fieldValue;
    let chunks;

    if (field) {
      fieldValue = getFieldValue(field);
      fieldValue = fixLists(fieldValue);
      chunks = getChunks(fieldValue, 100, lineCount);
    }

    let paginateChunks = false;
    if (chunks && chunks.length > 1) {
      paginateChunks = true;
    }

    return (
      <>
        <View style={lowMargin ? styles.lowMarginSection : styles.section} wrap={false}>
          <View wrap={false}>
            {!!title && <Text style={styles.questionTitle}>{title}</Text>}
            {!!subtitle && <Text style={styles.questionSubtitle}>{subtitle}</Text>}
            {!!subquestion && <Text style={{ marginVertical: 5, fontSize: 13 }}>{subquestion}</Text>}
          </View>
          {!!field ? (
            <View style={styles.field} wrap={false} break={paginateChunks ? true : false}>
              <Markdown source={chunks[0]} />
            </View>
          ) : null}
        </View>
        {!!field &&
          chunks.length > 1 &&
          chunks.map((chunk, index) => {
            if (index === chunks.length - 1) {
              return (
                <View key={chunk} style={styles.section} wrap={false} break={paginateChunks ? true: false}>
                  <View key={chunk} style={styles.field} wrap={false}>
                    <Markdown source={chunk} />
                  </View>
                </View>
              );
            }
            if (index > 0) {
              return (
                <View key={chunk} style={styles.field} wrap={false}>
                  <Markdown source={chunk} />
                </View>
              );
            } else {
              return null;
            }
          })}
      </>
    );
  };

  const SupportPeriods = () => {
    const currentPeriod = getFieldValue("currentSupportPeriod");
    let dates = {
      general: {
        given: [],
        repealed: [],
      },
      enhanced: {
        given: [],
        repealed: [],
      },
      special: {
        given: [],
        repealed: [],
      },
    };

    const fetch = getFieldValue("supportPeriodDates");

    if (fetch && fetch.length > 0) {
      dates = JSON.parse(fetch);
    }

    return (
      <View style={styles.periods} wrap={false}>
        <View style={styles.period}>
          <View style={styles.periodHeader}>
            <View style={currentPeriod === "general" ? styles.activeCheckbox : styles.checkbox} />
            <Text style={currentPeriod === "general" ? styles.periodTitleActive : styles.periodTitle}>
              {t("Vasu.general_support")}
            </Text>
          </View>
          <View style={styles.periodDates}>
            <Text style={styles.periodDateTitle}>{t("Vasu.date_given")}</Text>
            {dates &&
              dates.general &&
              dates.general.given &&
              dates.general.given.map((date) => (
                <Text style={styles.periodDate} key={date}>
                  {date}
                </Text>
              ))}
            <Text style={styles.periodDateTitle}>{t("Vasu.date_repealed")}</Text>
            {dates &&
              dates.general &&
              dates.general.repealed &&
              dates.general.repealed.map((date) => (
                <Text style={styles.periodDate} key={date}>
                  {date}
                </Text>
              ))}
          </View>
        </View>
        <View style={styles.period}>
          <View style={styles.periodHeader}>
            <View style={currentPeriod === "enhanced" ? styles.activeCheckbox : styles.checkbox} />
            <Text style={currentPeriod === "enhanced" ? styles.periodTitleActive : styles.periodTitle}>
              {t("Vasu.enhanced_support")}
            </Text>
          </View>
          <View style={styles.periodDates}>
            <Text style={styles.periodDateTitle}>{t("Vasu.date_given")}</Text>
            {dates &&
              dates.enhanced &&
              dates.enhanced.given &&
              dates.enhanced.given.map((date) => (
                <Text style={styles.periodDate} key={date}>
                  {date}
                </Text>
              ))}
            <Text style={styles.periodDateTitle}>{t("Vasu.date_repealed")}</Text>
            {dates &&
              dates.enhanced &&
              dates.enhanced.repealed &&
              dates.enhanced.repealed.map((date) => (
                <Text style={styles.periodDate} key={date}>
                  {date}
                </Text>
              ))}
          </View>
        </View>
        <View style={styles.lastPeriod}>
          <View style={styles.periodHeader}>
            <View style={currentPeriod === "special" ? styles.activeCheckbox : styles.checkbox} />
            <Text style={currentPeriod === "special" ? styles.periodTitleActive : styles.periodTitle}>
              {t("Vasu.special_support")}
            </Text>
          </View>
          <View style={styles.periodDates}>
            <Text style={styles.periodDateTitle}>{t("Vasu.date_given")}</Text>
            {dates &&
              dates.special &&
              dates.special.given &&
              dates.special.given.map((date) => (
                <Text style={styles.periodDate} key={date}>
                  {date}
                </Text>
              ))}
            <Text style={styles.periodDateTitle}>{t("Vasu.date_repealed")}</Text>
            {dates &&
              dates.special &&
              dates.special.repealed &&
              dates.special.repealed.map((date) => (
                <Text style={styles.periodDate} key={date}>
                  {date}
                </Text>
              ))}
          </View>
        </View>
      </View>
    );
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header} fixed>
          <View style={styles.headerLeft}>
            <Image
              style={{
                width: 60,
              }}
              src={tougoLogo}
            ></Image>
          </View>
          <View style={styles.headerRight}>
            <Text>{t("Vasu.confidential")}</Text>
            <Text>{t("Vasu.based_on_act")}</Text>
          </View>
        </View>
        <View style={styles.section} wrap={false}>
          <Text style={styles.title}>
            {t("Vasu.child_plan")} – 
            {document.publishedAt
              ? lowercaseFirstLetter(t("Vasu.published_version"))
              : lowercaseFirstLetter(t("Vasu.draft"))}
          </Text>
          <View style={styles.documentInfo}>
            <View style={styles.documentInfoRow}>
              <Text style={styles.documentInfoLabel}>
                {document.publishedAt ? t("Vasu.published") : t("Vasu.last_edited")}
              </Text>
              <Text style={styles.documentInfoValue}>
                {DateTime.fromISO(document.updatedAt).toFormat("d.L.yyyy, HH:mm")}
              </Text>
            </View>
            <View style={styles.documentInfoRow}>
              <Text style={styles.documentInfoLabel}>
                {document.publishedAt ? t("Vasu.published_by") : t("Vasu.last_edited_by")}
              </Text>
              <Text style={styles.documentInfoValue}>
                {document.lastUpdateAuthor.firstName} {document.lastUpdateAuthor.lastName}
              </Text>
            </View>
          </View>
          <View style={styles.childInfo}>
            <Text style={styles.questionTitle}>1. {t("Staff.Employees.NewEmployeeForm.basicInfo")}</Text>
            <View style={styles.childInfoRow}>
              <Text style={styles.childInfoLabel}>{t("Vasu.child_name")}</Text>
              <Text style={styles.childInfoValue}>{child.name}</Text>
            </View>
            <View style={styles.childInfoRow}>
              <Text style={styles.childInfoLabel}>{t("Vasu.languages")}</Text>
              <Text style={styles.childInfoValue}>{getFieldValue("childLanguages")}</Text>
            </View>
            <View style={styles.childInfoRow}>
              <Text style={styles.childInfoLabel}>{t("Vasu.born")}</Text>
              <Text style={styles.childInfoValue}>{DateTime.fromISO(child.dateOfBirth).toFormat("d.L.yyyy")}</Text>
            </View>
            <View style={styles.childInfoRow}>
              <Text style={styles.childInfoLabel}>{t("Vasu.daycare")}</Text>
              <Text style={styles.childInfoValue}>
                {child.daycareCenters && child.daycareCenters.length > 0 ? child.daycareCenters[0].name : "–"}
              </Text>
            </View>
            <View style={styles.childInfoRow}>
              <Text style={styles.childInfoLabel}>{t("Vasu.group")}</Text>
              <Text style={styles.childInfoValue}>
                {child.activeDaycareGroups && child.activeDaycareGroups.length > 0
                  ? child.activeDaycareGroups[0].name
                  : "–"}
              </Text>
            </View>
          </View>
        </View>

        <StandardField
          title={"2. " + t("Vasu.authors_of_plan")}
          subtitle={t("Vasu.authors_of_plan_description")}
          subquestion={t("Vasu.authors_of_plan_one")}
          field="planDevisingOne"
          lineCount={28}
          lowMargin
        />
        <StandardField
          field="planDevising"
          subtitle={t("Vasu.authors_of_plan_deprecated")}
          lineCount={28}
          lowMargin
        />
        <StandardField subquestion={t("Vasu.authors_of_plan_two")} field="planDevisingTwo" lineCount={50} lowMargin />
        <StandardField
          subquestion={t("Vasu.authors_of_plan_three")}
          field="planDevisingThree"
          lineCount={LINECOUNT}
          lowMargin
        />
        <StandardField subquestion={t("Vasu.authors_of_plan_four")} field="planDevisingFour" lineCount={LINECOUNT} />
        <StandardField
          title={"3. " + t("Vasu.multidisciplinary_cooperation")}
          subtitle={t("Vasu.multidisciplinary_cooperation_one")}
          field="multiCooperationOne"
          lineCount={LINECOUNT}
        />
        <StandardField
          subtitle={t("Vasu.multidisciplinary_cooperation_two")}
          field="multiCooperationTwo"
          lineCount={LINECOUNT}
        />
        <StandardField
          title={"4. " + t("Vasu.strengths_and_interests")}
          subtitle={t("Vasu.strengths_and_interests_description")}
          subquestion={t("Vasu.centre_comments")}
          field="strengthsAndInterests1"
          lineCount={LINECOUNT}
        />
        <StandardField subquestion={t("Vasu.guardian_comments")} field="strengthsAndInterests2" lineCount={LINECOUNT} />
        <StandardField
          title={"5. " + t("Vasu.child_statement")}
          subtitle={t("Vasu.child_statement_description")}
          field="childNarration"
          lineCount={LINECOUNT}
        />
        <StandardField
          title={"6. " + t("Vasu.notes_on_previous_plan")}
          subtitle={t("Vasu.notes_on_previous_plan_description")}
          field="notesOnPreviousPlan"
          lineCount={LINECOUNT}
        />
        <MatrixRow title={t("Vasu.routines")} row={1} />
        <MatrixRow title={t("Vasu.play_and_emotional")} row={2} />
        <MatrixRow title={t("Vasu.speech_development")} row={3} />
        <MatrixRow title={t("Vasu.motor_skills")} row={4} />
        <MatrixRow title={t("Vasu.cognitive_activity")} row={5} />
        <MatrixRow title={t("Vasu.cultural_awareness")} row={6} />
        <StandardField
          title={"8. " + t("Vasu.support_for_development_and_learning")}
          subtitle={t("Vasu.support_description")}
        />
        <StandardField title={"8.1. " + t("Vasu.support_one")} subtitle={t("Vasu.support_one_description")} />
        <SupportPeriods />
        <StandardField
          subtitle={t("Vasu.support_one_description_two")}
          subquestion={t("Vasu.support_one_one")}
          field="supportOneOne"
          lineCount={LINECOUNT}
          lowMargin
        />
        <StandardField subquestion={t("Vasu.support_one_two")} field="supportOneTwo" lineCount={LINECOUNT} lowMargin />
        <StandardField subquestion={t("Vasu.support_one_three")} field="supportOneThree" lineCount={LINECOUNT} lowMargin />
        <StandardField subquestion={t("Vasu.support_one_four")} field="supportOneFour" lineCount={LINECOUNT} />
        <StandardField
          title={"8.2. " + t("Vasu.support_two")}
          subtitle={t("Vasu.support_two_description")}
          subquestion={t("Vasu.support_one_two")}
          field="supportTwoOne"
          lineCount={LINECOUNT}
          lowMargin
        />
        <StandardField subquestion={t("Vasu.support_one_three")} field="supportTwoTwo" lineCount={LINECOUNT} lowMargin />
        <StandardField subquestion={t("Vasu.support_one_four")} field="supportTwoThree" lineCount={LINECOUNT} lowMargin />
        <StandardField subquestion={t("Vasu.support_two_four")} field="supportTwoFour" lineCount={LINECOUNT} />
        <StandardField
          title={"9. " + t("Vasu.other_issues")}
          subtitle={t("Vasu.other_issues_description")}
          field="otherFactors"
          lineCount={LINECOUNT}
        />
        <StandardField title={"10. " + t("Vasu.other_documents")} field="otherDocumentsAndPlans" lineCount={LINECOUNT} />
        <StandardField title={"11. " + t("Vasu.monitoring")} field="monitoringAndEvaluation" lineCount={LINECOUNT} />
        {getFieldValue("presentInDiscussion") && getFieldValue("presentInDiscussion").length > 2 ? (
          <StandardField
            title={"12. " + t("Vasu.participants")}
            subtitle={t("Vasu.participants_description")}
            field="presentInDiscussion"
            lineCount={LINECOUNT}
          />
        ) : null}
      </Page>
    </Document>
  );
};

export default VasuPDF;
