import React, { Component, lazy, Suspense, useEffect, useState } from "react";
import { Switch, Route, Redirect, matchPath } from "react-router-dom";
import dayjs from "dayjs";
import { DateTime, Settings as DateTimeSettings } from "luxon";
import "dayjs/locale/fi";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import Honeybadger from "honeybadger-js";
import { useQuery } from "@apollo/react-hooks";
import { InMemoryCache } from "apollo-cache-inmemory";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";

import { GET_GLOBAL_FILTERS, CURRENT_WORKER } from "./queries";

import "./App.scss";
import "./shared/styles/global.scss";

import Auth from "./layouts/Auth/Auth";

import Logo from "./components/Logo/Logo";
import DaycareSelector from "./components/DaycareSelector/DaycareSelector";
import GroupSelector from "./components/GroupSelector/GroupSelector";
import NavigationBar from "./components/NavigationBar/NavigationBar";

//icons
import HomeIcon from "mdi-react/HomeIcon";
import CalendarIcon from "mdi-react/CalendarIcon";
import CalendarStarIcon from "mdi-react/CalendarStarIcon";
import CalendarTextIcon from "mdi-react/CalendarTextIcon";
import MessageIcon from "mdi-react/MessageIcon";
import AccountMultipleIcon from "mdi-react/AccountMultipleIcon";
import SettingsIcon from "mdi-react/SettingsIcon";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import AccountTieIcon from "mdi-react/AccountTieIcon";
import ImageMultipleIcon from "mdi-react/ImageMultipleIcon";
import PlusIcon from "mdi-react/PlusIcon";
import QuestionMarkCircleIcon from "mdi-react/QuestionMarkCircleIcon";
import { EventCalendar } from "./screens/Events/EventCalendar";

import {
  Loader,
  ErrorMessage,
  CenteredContent,
  MainLayout,
  Sidebar,
  SidebarButton,
  SidebarDropdownButton,
  SidebarSubButton,
  SidebarHeading,
  SidebarPickerContainer,
  NoMatch,
  en,
  fi,
  pushQueryToHistory,
  tougoCookies,
  retry,
} from "./shared";

import ApolloClient from "apollo-boost";
import { ApolloProvider, Query } from "react-apollo";

import { IS_LOGGED_IN } from "./queries";
import RoleBoundary from "./components/RoleBoundary/RoleBoundary";
import { signOut } from "./helpers/sessionHelpers";

import VasuPDFContainer from "./screens/VasuPDFContainer";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: "INTERFACE",
          name: "Messager",
          possibleTypes: [
            {
              name: "DaycareCenter",
            },
            {
              name: "SimpleMessager",
            },
            {
              name: "Worker",
            },
            {
              name: "DaycareGroup",
            },
            {
              name: "Child",
            },
            {
              name: "Guardian",
            },
          ],
        },
        {
          kind: "INTERFACE",
          name: "Author",
          possibleTypes: [
            {
              name: "Worker",
            },
            {
              name: "Guardian",
            },
          ],
        },
        {
          kind: "INTERFACE",
          name: "Media",
          possibleTypes: [
            {
              name: "Photo",
            },
            {
              name: "Video",
            },
          ],
        },
        {
          kind: "INTERFACE",
          name: "Channel",
          possibleTypes: [
            {
              name: "MessageThread",
            },
            {
              name: "MessageThreadGroup",
            },
          ],
        },
        {
          kind: "INTERFACE",
          name: "MessagingSearchRow",
          possibleTypes: [
            {
              name: "MessageThread",
            },
            {
              name: "Message",
            },
            {
              name: "Announcement",
            },
          ],
        },
        {
          kind: "INTERFACE",
          name: "MessagingEntity",
          possibleTypes: [
            {
              name: "MessageThread",
            },
            {
              name: "MessageThreadGroup",
            },
            {
              name: "Announcement",
            },
          ],
        },
        {
          kind: "INTERFACE",
          name: "MessagingHeading",
          possibleTypes: [
            {
              name: "MessageThread",
            },
            {
              name: "Announcement",
            },
          ],
        },
      ],
    },
  },
});
const Dashboard = lazy(() => retry(() => import("./screens/Dashboard")));
const NewEventForm = lazy(() => retry(() => import("./screens/Events/NewEventForm")));
const Messages = lazy(() => retry(() => import("./screens/Messages")));
const Children = lazy(() => retry(() => import("./screens/Children")));
const Day = lazy(() => retry(() => import("./screens/Calendar/Day")));
const Week = lazy(() => retry(() => import("./screens/Calendar/Week")));
const Month = lazy(() => retry(() => import("./screens/Calendar/Month")));
const NewReservation = lazy(() => retry(() => import("./screens/Calendar/NewReservation")));
const NewVacationMarking = lazy(() => retry(() => import("./screens/Calendar/NewVacationMarking")));
const Settings = lazy(() => retry(() => import("./screens/Settings")));
const Gallery = lazy(() => retry(() => import("./screens/Gallery/Gallery")));
const GalleryItem = lazy(() => retry(() => import("./screens/Gallery/GalleryItem")));
const Login = lazy(() => retry(() => import("./screens/Login")));
const Daycares = lazy(() => retry(() => import("./screens/Admin/Daycares")));
const Daycare = lazy(() => retry(() => import("./screens/Admin/Daycare")));
const Employees = lazy(() => retry(() => import("./screens/Admin/Employees")));
const EmployeeProfile = lazy(() => retry(() => import("./screens/Admin/EmployeeProfile")));
const Regions = lazy(() => retry(() => import("./screens/Admin/Regions")));
const VasuReservation = lazy(() => retry(() => import("./screens/VasuReservation")));
const VasuDocument = lazy(() => retry(() => import("./screens/VasuDocument")));
const Support = lazy(() => retry(() => import("./screens/Support")));

const cache = new InMemoryCache({ fragmentMatcher });

const client = new ApolloClient({
  cache,
  uri: process.env.REACT_APP_TOUGO_API || "https://tougo-staging.api.touhula.io/graphql",
  clientState: {
    resolvers: {
      Mutation: {
        setSelectedDate: (_root, variables, { cache, getCacheKey }) => {
          const data = { selectedDate: variables.date };
          cache.writeData({ data });
          return null;
        },
        setSelectedDaycareCenter: (_root, variables, { cache, getCacheKey }) => {
          const data = { selectedDaycareCenter: variables.id };
          if ("group_id" in variables) {
            data["selectedDaycareGroup"] = variables.group_id;
          }
          if (!variables.id || variables.id.length === 0) {
            data["selectedDaycareGroup"] = null;
          }
          cache.writeData({ data });
          return null;
        },
        setSelectedDaycareGroup: (_root, variables, { cache, getCacheKey }) => {
          const data = { selectedDaycareGroup: variables.id };
          cache.writeData({ data });
          return null;
        },
      },
    },
  },
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      if (
        graphQLErrors.find(
          (error) =>
            error.message === "Authentication error" ||
            (error.path && error.path.length > 0 && error.path[0] === "currentWorker")
        )
      ) {
        signOutSession();
      } else if (graphQLErrors.find((error) => error.message === "Early education session not found")) {
        removeVasuSession();
      } else {
        Honeybadger.setContext({ graphql_errors: graphQLErrors });
      }
    }
  },
  request: (operation) => {
    Honeybadger.setContext({ graphql_operation: operation.operationName, graphql_query: operation.query });
    const currentLanguage = (tougoCookies.get("language") || "fi-FI").split("-")[0];
    const workerSessionId = tougoCookies.get("workerSessionId") || "";
    const workerSessionToken = tougoCookies.get("workerSessionToken") || "";
    const workerEarlyEducationSessionId = tougoCookies.get("workerEarlyEducationSessionId") || "";
    const workerEarlyEducationSessionToken = tougoCookies.get("workerEarlyEducationSessionToken") || "";
    operation.setContext({
      headers: {
        "Worker-Session-Id": workerSessionId,
        "Worker-Session-Token": workerSessionToken,
        "Worker-Early-Education-Session-Id": workerEarlyEducationSessionId,
        "Worker-Early-Education-Session-Token": workerEarlyEducationSessionToken,
        "Accept-Language": currentLanguage,
      },
    });
  },
});

client.onResetStore(() => {
  client.cache.writeData({
    data: {
      selectedDate: DateTime.local().toISODate(),
      selectedDaycareGroup: null,
      selectedDaycareCenter: null,
      isLoggedIn: false,
      vasuSessionActive: false,
      vasuSessionCreatedAt: null,
      workerRole: null,
    },
  });
});

client.cache.writeData({
  data: {
    isLoggedIn: !!tougoCookies.get("workerSessionToken"),
    vasuSessionActive: !!tougoCookies.get("workerEarlyEducationSessionToken"),
    vasuSessionCreatedAt: tougoCookies.get("workerEarlyEducationSessionCreatedAt") || null,
    selectedDate: DateTime.local().toISODate(),
    workerRole: tougoCookies.get("workerRole"),
  },
});

const signOutSession = () => {
  signOut(client);
};

const removeVasuSession = () => {
  client.cache.writeData({
    data: {
      vasuSessionActive: false,
      vasuSessionCreatedAt: null,
    },
  });
  tougoCookies.remove("workerEarlyEducationSessionToken");
  tougoCookies.remove("workerEarlyEducationSessionId");
  tougoCookies.remove("workerEarlyEducationSessionCreatedAt");
};

const initialLanguage = tougoCookies.get("language") || "fi-FI";
i18n.use(initReactI18next).init({
  fallbackLng: initialLanguage,
  resources: {
    en: { translation: en },
    fi: { translation: fi },
  },
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

i18n.changeLanguage(initialLanguage);
dayjs.extend(LocalizedFormat);
dayjs.locale(initialLanguage.split("-")[0]);
if (window.Beacon) {
  window.Beacon("config", {
    hideAvatars: true,
    labels: i18n.t("HelpScout", { returnObjects: true }),
  });
}
DateTimeSettings.defaultLocale = initialLanguage.split("-")[0];
DateTimeSettings.defaultZoneName = "Europe/Helsinki";
i18n.on("languageChanged", (language) => {
  dayjs.locale(language.split("-")[0]);
  DateTimeSettings.defaultLocale = language.split("-")[0];
  if (window.Beacon) {
    window.Beacon("config", {
      hideAvatars: true,
      labels: i18n.t("HelpScout", { returnObjects: true }),
    });
  }
});

//hack that "picks up" the url + params when router drops them
//backupSearch is passed to "component" in RouteWithLayout
//compare it to the route you think you have or should have
function useBackupSearch() {
  const [daycare, setDaycare] = useState("");
  const [group, setGroup] = useState("");
  let backupSearch = daycare + group;

  function setDaycareId(id) {
    setDaycare(id);
  }
  function setGroupId(id) {
    setGroup(id);
  }

  function setGroupAndDaycareId(arg) {
    return [arg, setGroupId, setDaycareId, setGroupAndDaycareId];
  }

  return [backupSearch, setGroupId, setDaycareId, setGroupAndDaycareId];
}

// HACK: hack to make sure /shared uses the same instance of dayjs as /customer or /staff
window.localizedDayjs = dayjs;

function useSecureRouting() {
  const { loading, error, data } = useQuery(CURRENT_WORKER);
  const [role, setRole] = useState(null);
  let path = window.location.pathname;

  useEffect(() => {
    if (data) {
      setRole(data.currentWorker.role);
    }
  }, [data]);

  const disabledRoutes = {
    COMMUNICATION: [
      //can only view and create massmessages
      "admin",
      "children",
      "calendar",
      "private",
      "group_discussion",
      "group_announcements",
      "public/new",
      "direct/new",
    ],
    EDUCATOR: [
      // admin actions and private messaging disabled
      "admin",
      "direct/new",
      "private",
    ],
    DAYCARE_MANAGER: [
      // mass message creation and regions disableds
      "announcement/new",
      "regions",
    ],
    SERVICE_MANAGER: ["direct/new"],
    DAYCARE_HELPER: ["vasu"],
    REGIONAL_MANAGER: ["direct/new", "private"],
    MANAGEMENT: ["announcement/new"],
  };

  let routeDisabled = false;
  for (let [key, value] of Object.entries(disabledRoutes)) {
    if (key === role) {
      //if some route is present in "path", route should be disabled
      routeDisabled = value.some((route) => path.includes(route));
    }
  }
  return [routeDisabled];
}

const RouteWithLayout = ({ component: Component, layout: Layout, isPrivate, create, ...rest }) => {
  const [backupSearch, setGroupId, setDaycareId, setGroupAndDaycareId] = useBackupSearch(
    new URLSearchParams(window.location.search).toString()
  );

  useEffect(() => {
    const searchQuery = new URLSearchParams(window.location.search);
    pushQueryToHistory(searchQuery);
    setGroupAndDaycareId(searchQuery.toString());
    const daycare_id = searchQuery.get("daycareId") || "";
    const group_id = searchQuery.get("groupId") || "";
    client.cache.writeData({
      data: {
        selectedDaycareCenter: daycare_id,
        selectedDaycareGroup: group_id,
      },
    });
    if (
      !matchPath(window.location.pathname, {
        path: "/calendar",
        exact: false,
        strict: false,
      })
    ) {
      client.cache.writeData({
        data: {
          selectedDate: DateTime.local().toISODate(),
        },
      });
    }
  });
  const { t } = useTranslation();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isPrivate) {
          return (
            <Query query={IS_LOGGED_IN}>
              {({ data }) =>
                data && data.isLoggedIn ? (
                  <SecureRoleChecker>
                    <Layout
                      top={(toggleSideNav) => <NavigationBar toggleSideNav={toggleSideNav} />}
                      aside={(sideNavOpen) => (
                        <Query query={GET_GLOBAL_FILTERS}>
                          {({ data, error, loading }) => {
                            if (error || loading) return <Loader text={t("General.loading")} centered large />;
                            const { selectedDaycareCenter, selectedDaycareGroup } = data;
                            if (error) return <ErrorMessage centered>{t("General.loading_error")}</ErrorMessage>;
                            if (loading) return <Loader text={t("General.loading")} centered />;
                            const searchQuery = new URLSearchParams();
                            if (selectedDaycareCenter) searchQuery.set("daycareId", selectedDaycareCenter);
                            if (selectedDaycareGroup) searchQuery.set("groupId", selectedDaycareGroup);
                            const search = searchQuery.toString();
                            return (
                              <Sidebar
                                open={sideNavOpen}
                                logo={<Logo />}
                                topContent={
                                  <RoleBoundary exclude={["COMMUNICATION"]}>
                                    <SidebarHeading text={t("Staff.SidebarHeading.daycare_center")} />
                                    <SidebarPickerContainer>
                                      <DaycareSelector setDaycareId={setDaycareId} setGroupId={setGroupId} />
                                      <GroupSelector setGroupId={setGroupId} />
                                    </SidebarPickerContainer>
                                  </RoleBoundary>
                                }
                                primaryNavigation={
                                  <>
                                    <SidebarHeading text={t("Staff.SidebarHeading.navigation")} />
                                    <PrimaryNavigationLinks component={SidebarButton} t={t} search={search} />
                                  </>
                                }
                                bottomNavigation={
                                  <>
                                    <BottomNavigationLinks component={SidebarButton} t={t} search={search} />
                                  </>
                                }
                              />
                            );
                          }}
                        </Query>
                      )}
                    >
                      <Suspense
                        fallback={
                          <CenteredContent>
                            <Loader text={t("General.loading")} centered large />
                          </CenteredContent>
                        }
                      >
                        <Component {...props} backupSearch={backupSearch} create={create} />
                      </Suspense>
                    </Layout>
                  </SecureRoleChecker>
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: { from: props.location },
                    }}
                  />
                )
              }
            </Query>
          );
        } else {
          return (
            <Layout>
              <Suspense
                fallback={
                  <CenteredContent>
                    <Loader text={t("General.loading")} centered large />
                  </CenteredContent>
                }
              >
                <Component {...props} />
              </Suspense>
            </Layout>
          );
        }
      }}
    />
  );
};

const SecureRoleChecker = ({ children, path }) => {
  const [routeDisabled] = useSecureRouting();
  if (routeDisabled) {
    return <NoMatch />;
  } else {
    return children;
  }
};

const RedirectWithQuery = ({ to, ...props }) => {
  const search = window.location.search;
  return <Redirect {...props} to={to + search} />;
};

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Switch>
          <RouteWithLayout exact path={"/login"} layout={Auth} component={Login} {...this.props} />
          <RedirectWithQuery exact path={"/"} to={"/home/dashboard"} />
          <RedirectWithQuery exact path={"/home"} to={"/home/dashboard"} />
          <RouteWithLayout
            isPrivate
            exact
            path={"/home/dashboard"}
            layout={MainLayout}
            component={Dashboard}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/home/daycare_activity"}
            layout={MainLayout}
            component={Dashboard}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/home/parents_activity"}
            layout={MainLayout}
            component={Dashboard}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/home/children"}
            layout={MainLayout}
            component={Dashboard}
            {...this.props}
          />
          <RedirectWithQuery exact path={"/messages"} to={"/messages/group_discussion"} />
          <RouteWithLayout isPrivate path={"/messages/"} layout={MainLayout} component={Messages} {...this.props} />
          <RouteWithLayout
            isPrivate
            path={"/messages/group_discussion/"}
            layout={MainLayout}
            component={Messages}
            {...this.props}
          />

          <RedirectWithQuery exact path={"/children"} to={"/children/list"} />
          <RouteWithLayout
            isPrivate
            exact
            path={"/children/list"}
            layout={MainLayout}
            component={Children}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/children/list/:id"}
            layout={MainLayout}
            component={Children}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/children/list/:id/vasu"}
            layout={MainLayout}
            component={Children}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/children/allergies"}
            layout={MainLayout}
            component={Children}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/children/photo_rights"}
            layout={MainLayout}
            component={Children}
            {...this.props}
          />
          <RedirectWithQuery exact path={"/calendar"} to={"/calendar/day/list"} />
          <RouteWithLayout isPrivate path={"/calendar/day/list/"} layout={MainLayout} component={Day} {...this.props} />
          <RouteWithLayout
            isPrivate
            exact
            path={"/calendar/day/graph"}
            layout={MainLayout}
            component={Day}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/calendar/week"}
            layout={MainLayout}
            component={Week}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/calendar/month"}
            layout={MainLayout}
            component={Month}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/calendar/new-reservation"}
            layout={MainLayout}
            component={NewReservation}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/calendar/new-vacation-marking"}
            layout={MainLayout}
            component={NewVacationMarking}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/admin/regions"}
            layout={MainLayout}
            component={Regions}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/admin/regions/:id"}
            layout={MainLayout}
            component={Regions}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/admin/regions/municipality/:municipalityId"}
            layout={MainLayout}
            component={Regions}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/admin/daycares"}
            layout={MainLayout}
            component={Daycares}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/admin/daycares/:id"}
            layout={MainLayout}
            component={Daycare}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/admin/employees"}
            layout={MainLayout}
            component={Employees}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/admin/employees/:id"}
            layout={MainLayout}
            component={EmployeeProfile}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            path={"/gallery/(all|shared|unshared|upload)"}
            layout={MainLayout}
            component={Gallery}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/gallery/:id"}
            layout={MainLayout}
            component={GalleryItem}
            {...this.props}
          />
          <RedirectWithQuery exact path={"/gallery"} to={"/gallery/all"} />
          <RouteWithLayout
            isPrivate
            exact
            path={"/vasu/discussions"}
            layout={MainLayout}
            component={VasuReservation}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/vasu/documents"}
            layout={MainLayout}
            component={VasuDocument}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/vasu/documents/:id"}
            layout={MainLayout}
            component={VasuDocument}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/vasu/documents/:id/document/:document_id"}
            layout={MainLayout}
            component={VasuDocument}
            {...this.props}
          />
          <Route
            isPrivate
            exact
            path={"/vasu/documents/:id/pdf/:document_id"}
            component={VasuPDFContainer}
            {...this.props}
          />
          <RedirectWithQuery exact path={"/vasu"} to={"/vasu/discussions"} />
          <RedirectWithQuery path={"/admin"} to={"/admin/employees"} />
          <RouteWithLayout
            isPrivate
            exact
            path={"/settings"}
            layout={MainLayout}
            component={Settings}
            cache={client.cache}
            {...this.props}
          />

          <RouteWithLayout
            isPrivate
            exact
            path={"/new-event"}
            layout={MainLayout}
            component={NewEventForm}
            {...this.props}
          />
          <RedirectWithQuery exact path={"/events"} to={"/events/upcoming"} />
          <RouteWithLayout
            isPrivate
            exact
            path={"/events/:time"}
            layout={MainLayout}
            component={EventCalendar}
            {...this.props}
          />
          <RouteWithLayout
            isPrivate
            exact
            path={"/events/:time/:id"}
            layout={MainLayout}
            component={EventCalendar}
            {...this.props}
          />
          <RouteWithLayout isPrivate exact path={"/support"} layout={MainLayout} component={Support} {...this.props} />
          <Route component={NoMatch} />
        </Switch>
      </ApolloProvider>
    );
  }
}

const MessageLinks = ({ accessToPrivateMessages, search, t }) => {
  return (
    <SidebarDropdownButton
      matchTo={`/messages`}
      to={{
        pathname: accessToPrivateMessages ? `/messages/private` : `/messages/group_discussion`,
        search: search,
      }}
      label={t("Staff.PrimaryNavigation.messages")}
      icon={<MessageIcon />}
    >
      <RoleBoundary accessibleBy={["DAYCARE_MANAGER", "MANAGEMENT", "SERVICE_MANAGER"]}>
        <SidebarSubButton to={{ pathname: `/messages/private`, search: search }} label={t("Messages.private")} />
      </RoleBoundary>
      <SidebarSubButton
        to={{ pathname: `/messages/group_discussion`, search: search }}
        label={t("Messages.group_discussion")}
      />
      <SidebarSubButton
        to={{ pathname: `/messages/group_announcements`, search: search }}
        label={t("Messages.group_announcements")}
      />
      <SidebarSubButton
        to={{
          pathname: `/messages/organization_announcements`,
          search: search,
        }}
        label={t("Messages.organization_announcements")}
      />
      <SidebarSubButton to={{ pathname: `/messages/public/new`, search: search }} label={t("NewThread.new_message")} />
      <RoleBoundary accessibleBy={["DAYCARE_MANAGER", "MANAGEMENT"]}>
        <SidebarSubButton
          to={{ pathname: `/messages/direct/new`, search: search }}
          label={t("NewThread.new_private_message")}
        />
      </RoleBoundary>
      <RoleBoundary accessibleBy={["MANAGEMENT", "SERVICE_MANAGER", "REGIONAL_MANAGER", "COMMUNICATION"]}>
        <SidebarSubButton
          to={{ pathname: `/messages/announcement/new`, search: search }}
          label={t("NewAnnouncement.new_announcement")}
        />
      </RoleBoundary>
    </SidebarDropdownButton>
  );
};

function PrimaryNavigationLinks(props) {
  const Component = props.component;
  const t = props.t;

  return (
    <Query query={GET_GLOBAL_FILTERS}>
      {({ data, error, loading }) => {
        if (error) return <ErrorMessage centered>{t("General.loading_error")}</ErrorMessage>;
        if (loading) return <Loader text={t("General.loading")} centered />;
        const { selectedDaycareCenter, selectedDaycareGroup } = data;
        const searchQuery = new URLSearchParams();
        if (selectedDaycareCenter) searchQuery.set("daycareId", selectedDaycareCenter);
        if (selectedDaycareGroup) searchQuery.set("groupId", selectedDaycareGroup);
        const search = searchQuery.toString();
        return (
          <>
            <Component
              to={{ pathname: `/home`, search: search }}
              label={t("Staff.PrimaryNavigation.front_page")}
              icon={<HomeIcon />}
            />
            <Query query={CURRENT_WORKER}>
              {({ data, error, loading }) => {
                if (error || loading) return "";
                const {
                  currentWorker: { role },
                } = data;

                if (["DAYCARE_MANAGER", "MANAGEMENT", "ADMINISTRATOR"].includes(role)) {
                  return <MessageLinks accessToPrivateMessages search={search} t={t} />;
                } else {
                  if (role === "COMMUNICATION") {
                    return (
                      <>
                        <SidebarDropdownButton
                          matchTo={`/messages/:message_type(organization_announcements|mass_messages|marketing_messages)`}
                          to={{
                            pathname: `/messages/organization_announcements`,
                            search: search,
                          }}
                          label={t("Staff.PrimaryNavigation.messages")}
                          icon={<MessageIcon />}
                        >
                          <SidebarSubButton
                            to={{ pathname: `/messages/mass_messages`, search: search }}
                            label={t("Messages.organization_announcements")}
                          />
                          <SidebarSubButton
                            to={{ pathname: `/messages/marketing_messages`, search: search }}
                            label={t("Messages.marketing_messages")}
                          />
                        </SidebarDropdownButton>
                        <Component
                          to={{
                            pathname: `/messages/announcement/new`,
                            search: search,
                          }}
                          label={t("NewAnnouncement.new_announcement")}
                          icon={<PlusIcon />}
                          exact
                        />
                      </>
                    );
                  } else {
                    return <MessageLinks search={search} t={t}></MessageLinks>;
                  }
                }
              }}
            </Query>
            <RoleBoundary exclude={["COMMUNICATION"]}>
              <SidebarDropdownButton
                matchTo={`/calendar`}
                to={{ pathname: `/calendar`, search: search }}
                label={t("Staff.PrimaryNavigation.calendar")}
                icon={<CalendarIcon />}
              >
                <SidebarSubButton
                  to={{ pathname: `/calendar/day/list`, search: search }}
                  label={t("Staff.PrimaryNavigation.calendar_day")}
                />
                <SidebarSubButton
                  to={{ pathname: `/calendar/week`, search: search }}
                  label={t("Staff.PrimaryNavigation.calendar_week")}
                />
                <SidebarSubButton
                  to={{ pathname: `/calendar/month`, search: search }}
                  label={t("Staff.PrimaryNavigation.calendar_month")}
                />
                <SidebarSubButton
                  to={{ pathname: `/calendar/new-reservation`, search: search }}
                  label={t("Staff.PrimaryNavigation.new_reservation")}
                />
                <SidebarSubButton
                  to={{
                    pathname: `/calendar/new-vacation-marking`,
                    search: search,
                  }}
                  label={t("Staff.PrimaryNavigation.new_vacation_marking")}
                />
              </SidebarDropdownButton>
              <RoleBoundary exclude={["COMMUNICATION"]}>
                <SidebarDropdownButton
                  matchTo={`/events`}
                  to={{ pathname: `/events/upcoming`, search: search }}
                  label={t("Eventcalendar.EventcalendarMenu")}
                  icon={<CalendarStarIcon />}
                >
                  <SidebarSubButton
                    to={{ pathname: `/events/upcoming`, search: search }}
                    label={t("Eventcalendar.EventcalendarMenu")}
                  />
                  <SidebarSubButton
                    to={{ pathname: `/new-event`, search: search }}
                    label={t("Eventcalendar.EventcalendarMenuNewEvent")}
                  />
                </SidebarDropdownButton>
              </RoleBoundary>
              <Component
                to={{ pathname: `/children`, search: search }}
                label={t("Staff.PrimaryNavigation.children_full")}
                icon={<AccountMultipleIcon />}
              />
              <Component
                to={{ pathname: `/gallery`, search: search }}
                label={"Galleria"}
                icon={<ImageMultipleIcon />}
              />
              <RoleBoundary exclude={["DAYCARE_HELPER"]}>
              <SidebarDropdownButton
                matchTo={`/vasu`}
                to={{ pathname: `/vasu/documents`, search: search }}
                label={t("Vasu.vasu_plans_title")}
                icon={<CalendarTextIcon />}
              >
                <SidebarSubButton to={{ pathname: `/vasu/documents`, search: search }} label={t("Vasu.plans")} />
                <SidebarSubButton
                  to={{ pathname: `/vasu/discussions`, search: search }}
                  label={t("Vasu.discussions_short")}
                />
              </SidebarDropdownButton>
              </RoleBoundary>
            </RoleBoundary>
            <RoleBoundary accessibleBy={["REGIONAL_MANAGER", "MANAGEMENT", "DAYCARE_MANAGER", "SERVICE_MANAGER"]}>
              <SidebarDropdownButton
                matchTo={`/admin`}
                to={{ pathname: `/admin`, search: search }}
                label={t("Staff.PrimaryNavigation.admin")}
                icon={<AccountTieIcon />}
              >
                <SidebarSubButton
                  to={{ pathname: `/admin/employees`, search: search }}
                  label={t("Staff.PrimaryNavigation.employees")}
                />
                <SidebarSubButton
                  to={{ pathname: `/admin/daycares`, search: search }}
                  label={t("Staff.PrimaryNavigation.daycare_centers")}
                />
                <RoleBoundary accessibleBy={["REGIONAL_MANAGER", "MANAGEMENT", "SERVICE_MANAGER"]}>
                  <SidebarSubButton
                    to={{ pathname: `/admin/regions`, search: search }}
                    label={t("Staff.PrimaryNavigation.munincipalities")}
                  />
                </RoleBoundary>
              </SidebarDropdownButton>
            </RoleBoundary>
          </>
        );
      }}
    </Query>
  );
}

function BottomNavigationLinks({ component, search, t }) {
  const Component = component;

  return (
    <>
      <Component
        to={{ pathname: `/support`, search: search }}
        label={t("CustomerApp.BottomNavigationLinks.support")}
        icon={<QuestionMarkCircleIcon />}
      />
      <Component
        to={{ pathname: `/settings`, search: search }}
        label={t("Staff.PrimaryNavigation.settings")}
        icon={<SettingsIcon />}
      />
    </>
  );
}

export default App;
