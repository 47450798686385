import React, { useState, useEffect } from "react";

import { withApollo } from "react-apollo";
import { useTranslation } from "react-i18next";
import gql from "graphql-tag";
import filesize from "filesize";

import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import Finnish from "@uppy/locales/lib/fi_FI";
import English from "@uppy/locales/lib/en_US";

import PaperclipIcon from "mdi-react/PaperclipIcon";
import CloseIcon from "mdi-react/CloseIcon";

import styles from "./AttachmentUpload.module.scss";

const AttachmentUpload = ({
  setAttachment,
  isUploading,
  setIsUploading,
  uppyReset,
  populatedAttachment,
  setUppyReset,
  vasu,
  client,
}) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [attachmentError, setAttachmentError] = useState(null);
  const [attachmentMeta, setAttachmentMeta] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadProgress, setUploadProgress] = useState("0%");

  let restrictions = {
    maxFileSize: 26214400,
    maxNumberOfFiles: 1,
    allowedFileTypes: [".pdf"],
  };

  if (vasu) restrictions["allowedFileTypes"] = [".pdf", ".docx", ".jpg", ".jpeg", ".png"];

  const uppy = Uppy({
    autoProceed: true,
    locale: i18n.language === "fi-FI" ? Finnish : English,
    allowMultipleUploads: false,
    restrictions: restrictions,
    meta: { client },
  }).use(AwsS3, {
    getUploadParameters: (file) => {
      const client = file.meta.client;
      return client
        .mutate({
          variables: { filename: file.name },
          mutation: gql`
            mutation GenerateUploadPresign($httpMethod: HTTPMethod, $filename: String) {
              generateUploadPresign(httpMethod: $httpMethod, filename: $filename) {
                fields
                headers
                httpMethod
                url
              }
            }
          `,
        })
        .then((result) => {
          return {
            method: result.data.generateUploadPresign.httpMethod,
            url: result.data.generateUploadPresign.url,
            fields: result.data.generateUploadPresign.fields,
            headers: result.data.generateUploadPresign.headers,
          };
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  useEffect(() => {
    if (uppyReset) {
      console.log("reset");
      setAttachmentMeta(null);
      setAttachmentError(null);
      setUploadSuccess(false);
      uppy.reset();
      document.getElementById("fileSelectItem").value = "";
      setUppyReset(false);
    }
  }, [uppyReset, uppy, setUppyReset]);

  uppy.on("upload", () => {
    console.log("upload");
    setIsUploading(true);
    setAttachmentError(null);
  });

  uppy.on("upload-progress", () => {
    console.log(uppy.getState().totalProgress);
    setUploadProgress(uppy.getState().totalProgress + "%");
  });

  uppy.on("complete", (result) => {
    if (result.failed.length) {
      setIsUploading(false);
      setUploadProgress("0%");
      return;
    }

    const attachmentInfo = {
      id: result.successful[0].meta.key.split("/")[1],
      storage: result.successful[0].meta.key.split("/")[0],
      metadata: {
        size: result.successful[0].size,
        filename: result.successful[0].name,
        mime_type: result.successful[0].type,
      },
    };
    console.log("setting attachment");
    setAttachment(attachmentInfo);
    setIsUploading(false);
    setUploadProgress("100%");
    setUploadSuccess(true);
  });

  useEffect(() => {
    if (populatedAttachment) {
      const attachmentInfo = {
        id: "",
        storage: "cache",
        metadata: {
          size: populatedAttachment.original.metadata.size,
          filename: populatedAttachment.original.metadata.filename,
          mime_type: "application/pdf",
        },
      };

      setAttachmentMeta({
        name: populatedAttachment.original.metadata.filename,
        size: populatedAttachment.original.metadata.size,
      });
      setAttachment(populatedAttachment);
    }
  }, []);

  let uploaderClassName = styles.attachmentSelector;
  if (vasu) uploaderClassName += ` ${styles.vasu}`;
  if (isUploading) uploaderClassName += ` ${styles.attachmentUploading}`;
  if (uploadSuccess) uploaderClassName += ` ${styles.attachmentSuccess}`;

  return (
    <div className={styles.root}>
      <input
        type="file"
        name="attachment"
        id="fileSelectItem"
        accept={vasu ? ".pdf, .docx, .jpg, .jpeg, .png" : ".pdf"}
        className={styles.attachmentInput}
        disabled={isUploading || uploadSuccess}
        onChange={(e) => {
          console.log(e.target.files);
          if (e.target.files.length > 0) {
            console.log("has file");
            try {
              uppy.reset();
              uppy.addFile({
                source: "file input",
                name: e.target.files[0].name,
                type: e.target.files[0].type,
                data: e.target.files[0],
              });

              setAttachmentMeta({
                name: e.target.files[0].name,
                size: e.target.files[0].size,
              });
            } catch (err) {
              console.log(err);
              if (err.isRestriction) {
                // handle restrictions
                setAttachmentError(err.toString());
              } else {
                // handle other errors
                setAttachmentError(err.toString());
              }
            }
          }
        }}
      />
      <label htmlFor="fileSelectItem" className={uploaderClassName}>
        <div className={styles.attachmentProgress} style={{ width: uploadProgress }} />
        {!attachmentMeta && <div className={styles.attachmentLabel}>{t("Messages.add_attachment")}</div>}
        <div className={styles.attachmentButton}>
          <PaperclipIcon />
        </div>
        {attachmentMeta && (
          <>
            <div title={attachmentMeta.name} className={styles.attachmentMeta}>
              <span className={styles.attachmentName}>{attachmentMeta.name}</span>
              <span className={styles.attachmentSize}>{filesize(attachmentMeta.size, { round: 1 })}</span>
            </div>
            {(uploadSuccess || populatedAttachment) && (
              <button
                className={styles.attachmentRemove}
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("fileSelectItem").value = "";
                  setAttachmentMeta(null);
                  setAttachment(null);
                  setUploadProgress("0%");
                  setUploadSuccess(false);
                  uppy.reset();
                }}
              >
                <CloseIcon />
              </button>
            )}
          </>
        )}
        {!attachmentMeta && vasu && (
          <div className={styles.attachmentMeta}>
            <span className={styles.attachmentName}>{t("Vasu.select_file")}</span>
          </div>
        )}
        {attachmentError && (
          <>
            <div title={attachmentError} className={styles.attachmentMeta}>
              <span className={styles.attachmentName}>{attachmentError}</span>
            </div>
            <button
              className={styles.attachmentRemove}
              onClick={(e) => {
                e.preventDefault();
                setAttachmentError(null);
              }}
            >
              <CloseIcon />
            </button>
          </>
        )}
      </label>
    </div>
  );
};

export default withApollo(AttachmentUpload);
