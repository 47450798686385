import Honeybadger from "honeybadger-js";
import HoneyBadgerErrorBoundary from "@honeybadger-io/react";

import { tougoCookies } from "../../shared";

const honeybadgerApiKey = process.env.REACT_APP_HONEYBADGER_API_KEY || "xxxxxxxx";
const honeybadgerEnv = process.env.REACT_APP_HONEYBADGER_ENV || process.env.REACT_APP_CONTEXT || process.env.NODE_ENV;

const config = {
  api_key: honeybadgerApiKey,
  environment: honeybadgerEnv,
  revision: process.env.REACT_APP_COMMIT_REF || "master",
  debug: process.env.NODE_ENV !== "production",
  disabled: process.env.NODE_ENV !== "production",
};

const honeybadger = Honeybadger.configure(config);

class ErrorBoundary extends HoneyBadgerErrorBoundary {
  componentDidMount() {
    Honeybadger.setContext({ user_id: tougoCookies.get("workerId") });
  }
}

ErrorBoundary.defaultProps = { honeybadger };

export default ErrorBoundary;
